
import Vue from "vue";
import copyToClipboard from 'quasar/src/utils/copy-to-clipboard.js';;
import { Info, Success, Warn } from "@/services/notif-utils";
import api from "@/api";
const columns = [
  {
    name: "location",
    label: "Location",
    field: "Location",
    align: "center",
  },
  {
    name: "date",
    label: "Date",
    field: "Date",
    align: "center",
  },
  {
    name: "local_time",
    label: "Local Time",
    field: "Local Time",
    align: "center",
  },
  {
    name: "description",
    label: "Description",
    field: "Description",
    align: "center",
  },
];
export default Vue.extend({
  name: "TrackingDetailsModal",
  props: { show: Boolean, trackingCode: String },
  data() {
    return {
      loading: false,
      data: {},
      columns: columns,
    };
  },
  computed: {
    getTrackingCode(): string {
      return this.trackingCode;
    },
    showModal(): boolean {
      return this.show;
    },
    latestUpdate(): any[] {
      if (this.data) {
        // @ts-expect-error
        return [this.data["latest_update"]];
      }
      return [];
    },
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    copy(text: string) {
      copyToClipboard(text)
        .then(() => {
          Info(`${text} Copied to clipboard successfully :)`);
        })
        .catch(() => {
          Warn("Failed to copy :(");
        });
    },
    loadTrackingDetails() {
      this.loading = true;
      if (!this.trackingCode) {
        Warn("No Tracking Code Loaded");
        return;
      }
      const data = {
        trackingNumber: this.trackingCode,
      };
      api.Staff.getTrackingDetails(data)
        .then((response: any) => {
          this.data = JSON.parse(response.details);
          this.loading = false;
          Success("Successfully loaded tracking details!");
        })
        .catch((error) => {
          console.log(error);
          Warn("Unable To get Tracking Details");
          this.loading = false;
        });
    },
  },
  created() {
    this.loadTrackingDetails();
  },
});
