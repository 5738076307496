import Util from "./util";
const SHIPSITE_API_URL = "/shipsite-api";
const axios = Util.createAxiosInstance(SHIPSITE_API_URL);

export default {
  customerOrderHistory(payload: any) {
    return new Promise((resolve, reject) => {
      const url = `/customerOrderHistory/?username=${payload["username"]}`;
      axios
        .get(url)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  internalNotesForOrders(order_id: any) {
    return new Promise((resolve, reject) => {
      // const url = `/internalNotesForOrder/`;
      const url = `/internalNotesForOrder/?order_id=${order_id}`;
      axios
        .get(url)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  orderDetails(order_id: string) {
    return new Promise((resolve, reject) => {
      const url = `/furtherOrderDetails/?order_id=${order_id}`;
      axios
        .get(url)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  apiOrderData(order_id: string) {
    return new Promise((resolve, reject) => {
      const url = `/orderInfoForCS/${order_id}`;
      axios
        .get(url)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateOrder(order_id: string, payload: "can_ship" | "is_urgent") {
    return new Promise((resolve, reject) => {
      const url = `/shipping-v2/Orders/${order_id}/`;
      const key = payload;
      const data = {} as any;
      data[key] = 1;
      axios
        .patch(url, data)
        .then((response: any) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
