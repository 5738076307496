
import Vue from "vue";
import { mapMutations } from "vuex";
import { MutationTypes } from "@/store/general/mutation-types";
import Api from "@/api";
import { Warn, Info, Success } from "@/services/notif-utils";
export default Vue.extend({
  name: "RemoveFeedback",
  props: ["feedback", "orderID", "currentAccountAbbrev"],
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    canRemoveFeedback() {
      if (this.getFeedback == "neutral" || this.getFeedback == "negative") {
        return true;
      }
      return false;
    },
    getFeedback(): string {
      return this.feedback;
    },
  },
  methods: {
    ...mapMutations({
      postAction: "General/" + MutationTypes.ACTION_POSTED,
    }),
    fetchUUID(): any {
      return new Promise((resolve, reject) => {
        Api.getUUID()
          .then((uuid) => {
            resolve(uuid);
            return uuid;
          })
          .catch((error) => {
            reject(error);
            return "";
          });
        return "";
      });
    },
    async removeFeedback() {
      this.loading = true;
      const uuid = await this.fetchUUID();
      const data = {
        orderID: this.orderID,
        uuid: uuid,
        accountAbbrev: this.currentAccountAbbrev,
      };
      const actionType = Api.Staff.actionType.OUTBOUND_QUEUE;
      Api.Staff.removeFeedback(data)
        .then(async (response: any) => {
          Info("Feedback request created...");
          Info("Carrying out Action in browser...Please wait :)");
          this.postAction();
          // const oqId = response.data.id;
          // const browserSuccess = await Api.Staff.checkIfSuccess(
          //   actionType,
          //   oqId
          // );
          // if (browserSuccess) {
          this.loading = false;
          return;
          // }
          // Error("Unable to remove feedback");
          // this.loading = false;
          // return;
        })
        .catch((error) => {
          console.log(error);
          Warn("Unable to set Feedback Request");
          this.loading = false;
        });
    },
  },
});
