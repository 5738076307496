
import Vue from "vue";
import OrderPageLink from "./OrderPageLink.vue";
const columns = [
  {
    name: "date_is_processed",
    field: "date_is_processed",
    label: "Date Processed",
    align: "center",
  },
  //   {
  //     name: "date_is_processed",
  //     field: "date_is_processed",
  //     label: "Date Processed",
  //     align: "center",
  //   },
  {
    name: "id",
    field: "id",
    label: "ID",
    align: "center",
  },
  {
    name: "original_order_id",
    field: "original_order_id",
    label: "Original Order ID",
    align: "center",
  },
  {
    name: "item_id",
    field: "item_id",
    label: "Item ID",
    align: "center",
  },
];
export default Vue.extend({
  name: "QRCodeModal",
  components: {
    OrderPageLink,
  },
  props: ["showModal", "data", "messageId", "accountAbbrev", "mainOrderId"],
  data() {
    return {
      loading: false,
      columns: columns,
    };
  },
  computed: {
    toggleState(): boolean {
      return this.showModal;
    },
  },
  methods: {
    close() {
      return this.$emit("closeModal");
    },
  },
});
