
/* eslint-disable camelcase */
import Vue from "vue";
import { MutationTypes } from "@/store/general/mutation-types";
import StaffApi from "@/api/staff";
import { Warn, Info, Success } from "@/services/notif-utils";
import AccountAbbrev from "@/services/AccountAbbrev";
import Api from "@/api/index";
import { mapMutations } from "vuex";
export default Vue.extend({
  name: "ModalSendRefund",
  props: [
    "openModal",
    "shippingCost",
    "orderId",
    "totalCost",
    "currentAccountAbbrev",
  ],
  data() {
    return {
      loading: false,
      error: "",
      refundAmount: "",
      hover: true,
      muuid: "",
      // invalidAmount: true,
    };
  },
  // watch: {
  //   refundAmount() {
  //     const maskPlaceholder = "0.00";
  //     const amount = this.refundAmount;
  //     if (Number(amount) > this.totalOrderCost) {
  //       this.invalidAmount = true;
  //     }
  //     if (Number(amount) === 0.0) {
  //       this.invalidAmount = true;
  //     }
  //     if (amount.toString() === maskPlaceholder) {
  //       this.invalidAmount = true;
  //     }
  //     this.invalidAmount = false;
  //   },
  // },
  computed: {
    toggleState(): boolean {
      return this.openModal;
    },
    halfShippingPrice(): any {
      if (this.shippingCost) {
        const amount = Math.round((this.shippingCost * 100) / 2) / 100;
        return amount.toFixed(2);
      }
      return 0;
    },
    invalidAmount() {
      const maskPlaceholder = "#.##";
      const amount = this.refundAmount;
      if (Number(amount) > this.totalOrderCost) {
        return true;
      }
      if (Number(amount) === 0.0) {
        return true;
      }
      if (amount.toString() === maskPlaceholder) {
        return true;
      }
      if (!Number(amount)) {
        return true;
      }
      return false;
    },
    totalOrderCost(): any {
      let re: any;
      if (this.currentAccountAbbrev == "KUS") {
        re = /\$/;
      } else if (this.currentAccountAbbrev == "KEU") {
        re = /EUR/;
      } else {
        re = /CHF/;
      }
      const priceWithoutCurrency = this.totalCost["price"].replace(re, "");
      return Number(priceWithoutCurrency);
    },
  },
  methods: {
    ...mapMutations({
      postAction: "General/" + MutationTypes.ACTION_POSTED,
    }),
    fetchUUID(): any {
      return new Promise((resolve, reject) => {
        Api.getUUID()
          .then((uuid) => {
            resolve(uuid);
            return uuid;
          })
          .catch((error) => {
            reject(error);
            return "";
          });
        return "";
      });
    },

    async sendRefund(amount: any) {
      this.loading = true;
      const uuid = await this.fetchUUID();
      const data = {
        orderId: this.orderId,
        uuid: uuid,
        amount: amount,
        accountAbbrev: this.currentAccountAbbrev,
      };
      Api.Staff.sendRefund(data)
        .then(async (response: any) => {
          const actionType = Api.Staff.actionType.OUTBOUND_QUEUE;
          Info(`Refund Request of $${amount} Created!`);
          Info("Executing refund in browser, please wait... :)");
          this.postAction();
          // const oqId = response.data.id;
          // const browserSuccess = await Api.Staff.checkIfSuccess(
          //   actionType,
          //   oqId
          // );
          // if (browserSuccess) {
          this.loading = false;

          this.$emit("closeModal");
          return;
          // } else {
          //   Error("Unable to run browser action. :(");
          //   this.loading = false;
          //   return;
          // }
        })
        .catch((error) => {
          console.log(error);
          Warn("Refund Request Failed");
          this.loading = false;
        });
    },
    async customRefund() {
      const amount = this.refundAmount;
      await this.sendRefund(amount);
    },
    async fullShippingRefund() {
      const amount = this.shippingCost;
      await this.sendRefund(amount);
    },
    async halfRefund() {
      const amount = this.halfShippingPrice;
      await this.sendRefund(amount);
    },
    getRefundLabel() {
      if (this.refundAmount) {
        return "Refund: " + "$" + this.refundAmount;
      }
      return "Custom Refund";
    },
    onSubmit() {
      //Comment
    },
    // closeModal() {
    //   this.$emit("closeModal");
    // },
  },
});
