
import Vue from "vue";
import api from "@/api/staff";
import DateUtils from "@/services/date-utils";
import ModalQRCodeStats from "./ModalQRCodeStats.vue";
import ModalInternalNotes from "./ModalInternalNotes.vue";
import OrderPageLink from "./OrderPageLink.vue";
const columns = [
  {
    name: "id",
    field: "id",
    label: "IID",
    align: "left",
  },
  {
    name: "qr_codes",
    field: "order_items",
    label: "Date Processed (QR)",
    align: "left",
  },
  {
    name: "order_id",
    field: "order_id",
    label: "Order ID",
    align: "center",
  },

  {
    name: "order_items",
    field: "order_items",
    label: "Latest Date Processed (QR)",
    align: "center",
  },

  {
    name: "shipping_method",
    field: "shipping_method",
    label: "Shipping Method",
    align: "center",
  },
  {
    name: "tracking_code",
    field: "tracking_code",
    label: "Tracking Code",
    align: "center",
  },
  {
    name: "manager_comments",
    field: "manager_comments",
    label: "Manager Comments",
    align: "right",
  },
];
export default Vue.extend({
  name: "modalCustomerHistory",
  props: ["showModal", "customerHistory", "messageId", "accountAbbrev"],
  components: {
    ModalQRCodeStats,
    ModalInternalNotes,
    OrderPageLink,
  },
  data() {
    return {
      loading: false,
      active: true,
      intervalID: null || Number(),
      syncInProgress: false,
      showQrCodeStats: false,
      columns: columns,
      qrItems: [],
      currentOrderItems: [],
      showInternalNotes: false,
      currentNotes: [],
    };
  },
  computed: {
    data(): any {
      return this.customerHistory;
    },
    getMessageId(): string {
      // Needed for Order Page Link Navigation
      return this.messageId;
    },
    getAccountAbbrev(): string {
      // Needed for Order Page link Navigation
      return this.accountAbbrev;
    },
  },
  methods: {
    getNotesCount(notes: any) {
      if (notes) {
        return notes.length;
      }
      return 0;
    },
    openInternalNotesModal(notes: any) {
      this.currentNotes = notes;
      this.showInternalNotes = true;
    },
    closeInternalNotesModal() {
      this.currentNotes = [];
      this.showInternalNotes = false;
    },
    close() {
      this.$emit("closeModal");
    },
    openQrDetailsModal(orderItems: any) {
      this.currentOrderItems = orderItems;
      this.showQrCodeStats = true;
    },
    closeQrDetailsModal() {
      this.showQrCodeStats = false;
    },
    multipleCommentsPresentForOrder(managerCommentsList: any): boolean {
      if (managerCommentsList.length > 1) {
        return true;
      }
      return false;
    },
    getQRCodeStats(orderItems: any): string {
      const shipSiteOrderItemsArray = orderItems;
      this.qrItems = shipSiteOrderItemsArray as any;
      // this.qrCodeModal = true;
      if (shipSiteOrderItemsArray == null) {
        return "";
      }
      const itemsCount = shipSiteOrderItemsArray.length;
      const isProcessedArray = shipSiteOrderItemsArray.filter((el: any) => {
        return el["is_processed"] == true;
      });
      const processedCount = isProcessedArray.length;
      const statsString = `${processedCount} of ${itemsCount} are Ready...`;
      return statsString;
    },
    sortQrCodeProccessedOrders(orderItems: any) {
      const items: any[] = [];
      console.log(orderItems);
      const shipSiteOrderItemsArray = orderItems;
      if (shipSiteOrderItemsArray == null) {
        return "";
      }
      for (const orderitem of shipSiteOrderItemsArray) {
        items.push(orderitem);
      }
      items.sort((a, b) => {
        if (a.is_processed && b.is_processed) {
          const d1 = new Date(a.date_is_processed_full);
          const d2 = new Date(b.date_is_processed_full);
          if (d1 < d2) {
            return -1;
          }
          if (d1 === d2) {
            return 0;
          }
          return 1;
        }
        return 0;
      });
      return items;
    },
    getQrLastProccessedDate(orderItems: any) {
      const sortedList = this.sortQrCodeProccessedOrders(orderItems);
      if (sortedList) {
        // console.log(sortedList);
        const latest = sortedList.pop();
        if (latest) {
          if (latest["date_is_processed"] == undefined) {
            return "";
          }
          return latest["date_is_processed"];
        }
        return "No Date Yet";
      }
      return;
    },
  },
});
