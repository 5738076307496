
import Vue from "vue";
import { mapMutations } from "vuex";
import { MutationTypes } from "@/store/general/mutation-types";
import StaffApi from "@/api/staff";
import api from "@/api";
import SelectShippingService from "./SelectShippingService.vue";
import { Info, Success, Warn } from "@/services/notif-utils";
export default Vue.extend({
  name: "ShippingPriceInputModal",
  components: {
    SelectShippingService,
  },
  props: ["orderId", "showModal", "currentAccountAbbrev"],
  data() {
    return {
      loading: false,
      shippingPrice: "",
      shippingService: [],
      selectedService: "",
    };
  },
  computed: {
    toggleState(): boolean {
      return this.showModal;
    },
  },
  methods: {
    ...mapMutations({
      postAction: "General/" + MutationTypes.ACTION_POSTED,
    }),
    validatePrice() {
      const price = this.shippingPrice;
      if (price == "") {
        return false;
      }
      const priceAsInt = Number(this.shippingPrice);
      if (!priceAsInt) {
        return false;
      }
      if (priceAsInt < 0) {
        return false;
      }
      return true;
    },
    fetchUUID(): any {
      return new Promise((resolve, reject) => {
        api
          .getUUID()
          .then((uuid) => {
            resolve(uuid);
            return uuid;
          })
          .catch((error) => {
            reject(error);
            return "";
          });
        return "";
      });
    },
    async onSubmit() {
      if (this.selectedService == "") {
        Info("Please Select A Shipping Service!");
        return;
      }
      if (this.shippingPrice == "") {
        Info("Please Input a shipping price!");
        return;
      }
      const priceIsValid = this.validatePrice();
      if (!priceIsValid) {
        Info("Please Input a valid Price");
        return;
      }
      const uuid = await this.fetchUUID();
      const data = {
        orderId: this.orderId,
        uuid: uuid,
        shippingMethod: this.selectedService,
        shippingPrice: this.shippingPrice,
      };
      StaffApi.changeShippingService(data, this.currentAccountAbbrev)
        .then((response) => {
          Info("Discogs Action Created, monitor status in actions tab..:)");
          this.postAction();
          this.loading = false;
          this.reset();
          this.closeModal();
          return;
        })
        .catch((error) => {
          console.log(error);
          Warn("Unable to set Service");
          return;
        });
    },
    closeModal() {
      return this.$emit("closeModal");
    },
    reset() {
      this.shippingPrice = "";
      this.selectedService = "";
      return;
    },
    selectService(service: string) {
      this.selectedService = service;
    },
  },
});
