
import Vue from "vue";
import { mapGetters, mapMutations } from "vuex";
import { Info } from "@/services/notif-utils";
import { MessageMutations } from "@/store/messages/mutation-types";
import AccountAbbrev from "@/services/AccountAbbrev";
import { NavigationFailureType, isNavigationFailure } from "vue-router";
export default Vue.extend({
  name: "OrderPageLink",
  props: ["orderId", "messageId", "accountAbbrev"],
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      isNextPage: "Message/isNextPage",
      isInMessages: "Message/orderIdInMessageList",
    }),
  },
  methods: {
    ...mapMutations({
      setIsNextPage: "Message/" + MessageMutations.SET_IS_NEXT_PAGE,
    }),
    openDiscogsPage() {
      const url = `https://www.discogs.com/sell/order/` + this.orderId;
      window.open(url, "_blank")?.focus();
    },
    openOrderPage() {
      const wasFound: boolean = this.isInMessages(this.orderId);
      if (wasFound == false) {
        Info("Message Is Not in Message List, loading from history...");
      }
      this.setIsNextPage(false);
      return this.$router
        .push({
          name: "OrderDetail",
          params: {
            messageId: this.messageId,
            orderId: this.orderId,
            accountAbbrev: this.accountAbbrev as AccountAbbrev,
          },
        })
        .catch((failure) => {
          if (isNavigationFailure(failure, NavigationFailureType.duplicated)) {
            return Info(
              "Duplicate Navigation: You are currently in this order page."
            );
          }
        });
    },
  },
});
