
export default {
  name: "ModalInternalNotes",
  props: ["showModal", "notes"],
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    getNotes(): any {
      return this.notes;
    },
    getModalState(): any {
      return this.showModal;
    },
  },
  methods: {
    close() {
      this.$emit("closeModal");
    },
    getUserAbbrev(creator: string) {
      if (!creator) {
        return "";
      }
      const stringList = creator.split("");
      const firstLetter = stringList[0];
      const secondLetter = stringList[1];
      const abbrev = firstLetter + secondLetter;
      return abbrev.toUpperCase();
    },
  },
};
