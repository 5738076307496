import { render, staticRenderFns } from "./AddressDetails.vue?vue&type=template&id=5bc043ba"
import script from "./AddressDetails.vue?vue&type=script&lang=ts"
export * from "./AddressDetails.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QCard from 'quasar/src/components/card/QCard.js';
import QTabs from 'quasar/src/components/tabs/QTabs.js';
import QTab from 'quasar/src/components/tabs/QTab.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QTabPanels from 'quasar/src/components/tab-panels/QTabPanels.js';
import QTabPanel from 'quasar/src/components/tab-panels/QTabPanel.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QCard,QTabs,QTab,QSeparator,QTabPanels,QTabPanel});
