
import Vue from "vue";
import ShipsiteApi from "@/api/shipping-api-cors/shipping";
import ModalConfirm from "./ModalConfirm.vue";
import { Warn, Success, Info } from "@/services/notif-utils";
export default Vue.extend({
  name: "ModalOrderDetails",
  components: {
    ModalConfirm,
  },
  props: ["openModal", "orderId"],
  data() {
    return {
      loading: false,
      details: {} as any,
      confirmDialog: false,
      confirmDialogMessage: "",
      currentConfirmationType: "" as "can_ship" | "is_urgent",
    };
  },
  computed: {
    getOrderId(): string {
      return this.orderId;
    },
    toggleState(): boolean {
      return true;
    },
    orderDetailsLoaded(): boolean {
      const lengthOfObject = Object.keys(this.details).length;
      if (lengthOfObject > 0) {
        return true;
      }
      return false;
    },
  },
  methods: {
    getIsManualColorClass(isAutomated: any) {
      const check = !!isAutomated;
      if (check) {
        return "positive";
      }
      return "primary";
      //
    },
    getIsManualLabel(isAutomated: boolean) {
      const check = !!isAutomated;
      if (check) {
        return "Automatically";
      }
      return "Manual";
    },
    getIsManualText(isAutomated: any, date: any) {
      let dateString = "";
      if (date) {
        dateString = "on" + "" + date;
      }
      const textAuto = `Order will be marked as shipped automatically ${dateString}`;
      const textManual = `Order will be marked as shipped manually ${dateString}`;

      if (isAutomated) {
        return textAuto;
      }
      return textManual;
    },
    close() {
      this.$emit("closeModal");
    },
    loadDetails() {
      this.loading = true;
      ShipsiteApi.orderDetails(this.getOrderId)
        .then((response: any) => {
          const responseData = response.data;
          this.details = responseData["data"];
          this.loading = false;
        })
        .catch((error: any) => {
          console.log(error);
          this.loading = false;
        });
    },
    confirmChoice(boolType: "can_ship" | "is_urgent") {
      if (boolType === "can_ship") {
        this.confirmDialogMessage =
          "Are you sure you want to set this as Can Ship?";
        this.confirmDialog = true;
        this.currentConfirmationType = boolType;
        return;
      }
      this.confirmDialogMessage =
        "Are you sure you want to set this as Urgent?";
      this.confirmDialog = true;
      this.currentConfirmationType = boolType;
      return;
    },
    saveOrderBoolean() {
      ShipsiteApi.updateOrder(this.getOrderId, this.currentConfirmationType)
        .then((responseData: any) => {
          Success("Successfully Conducted Change!");
        })
        .catch((error) => {
          console.log(error);
          Warn("Unable to change Value");
        });
    },
    closeConfirm() {
      this.confirmDialog = false;
      this.confirmDialogMessage = "";
      // this.currentConfirmationType = "";
    },
  },
  created() {
    this.loadDetails();
  },
});
