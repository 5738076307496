
import Vue from "vue";

export default Vue.extend({
  name: "ModalConfirmDelete",
  props: ["openModal", "message"],
  data() {
    return {};
  },
  methods: {
    confirm() {
      this.$emit("confirm");
      this.closeModal();
    },
    closeModal() {
      this.$emit("close");
    },
  },
  computed: {
    toggleState: function(): boolean {
      return this.openModal;
    },
  },
});
