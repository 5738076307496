
import Vue from "vue";
import { mapMutations } from "vuex";
import api from "@/api/staff";
import { Info, Warn } from "@/services/notif-utils";
import ModalDiscogsActions from "./ModalDiscogsActions.vue";
import ModalShipsiteActions from "./ModalShipsiteActions.vue";
import { MutationTypes } from "@/store/general/mutation-types";
import { isThisSecond } from "date-fns";
export default Vue.extend({
  name: "ActionStatusLauncher",
  props: ["orderId"],
  components: {
    ModalDiscogsActions,
    ModalShipsiteActions,
  },
  data() {
    return {
      loading: false,
      orderActions: [],
      outboundQueue: [],
      showShipsiteModal: false,
      showDiscogsModal: false,
      syncInProgress: false,
    };
  },
  watch: {
    actionPosted() {
      this.init();
    },
    getOrderId() {
      if (this.getOrderId) {
        this.init();
      }
    },
  },
  computed: {
    getOrderId(): any {
      return this.orderId;
    },
    actionPosted(): number {
      return this.$store.state.General.actionsPosted;
    },
    totalActions(): number {
      const totalCount = this.shipsiteActionsCount + this.discogsActionsLength;
      return totalCount;
    },
    shipsiteActionsCount(): number {
      if (this.orderActions) {
        return this.orderActions.length;
      }
      return 0;
    },
    discogsActionsLength(): number {
      if (this.outboundQueue) {
        return this.outboundQueue.length;
      }
      return 0;
    },
    shipSitePendingActionsCount(): number {
      if (this.orderActions) {
        const loadingActions = this.orderActions.filter((action: any) => {
          return action.processed == false;
        });
        return loadingActions.length;
      }
      return 0;
    },
    oqPendingActionsCount(): number {
      if (this.outboundQueue) {
        const loadingOq = this.outboundQueue.filter((oqItem: any) => {
          return oqItem.processed == false;
        });
        return loadingOq.length;
      }
      return 0;
    },
  },
  methods: {
    async sync(auto: boolean) {
      if (auto) {
        this.syncInProgress = true;
      }
      await this.init();
      // this.syncInProgress = false;
    },
    onClick() {
      //
    },
    async init() {
      this.loading = true;
      await this.loadActions();
      await this.loadOutbound();
      this.loading = false;
    },
    loadActions() {
      // Actions are gotten from Order Actions,
      // Actions are to be carried out on Shipsite
      api
        .getOrderActions(this.orderId)
        .then((responseData: any) => {
          if (responseData["orderActions"]) {
            this.orderActions = responseData["orderActions"];
            Info("Order Actions Successfully loaded!");
          }
        })
        .catch((error) => {
          console.log(error);
          Warn("Unable to Load Order Actions");
        });
    },
    loadOutbound() {
      // Outbound are gotten from OutboundQueue and rep,
      // OutboundQueue are actions to be carried out on Discogs
      api
        .getOutboundQueue(this.orderId)
        .then((responseData: any) => {
          if (responseData["outboundQueue"]) {
            this.outboundQueue = responseData["outboundQueue"];
            // Info("Outbound Queue Successfully loaded!");
          }
        })
        .catch((error) => {
          console.log(error);
          // Warn("Unable to Load Outbound Queue");
        });
    },
    getActionsLabel() {
      const prefix = "Actions";
      const suffix = this.totalActions;
      return `${prefix} (${suffix})`;
    },
    getShipsiteLabel() {
      const prefix = "Shipsite";
      const suffix = this.shipsiteActionsCount;
      return `${prefix} (${suffix})`;
    },
    getDiscogsLabel() {
      const prefix = "Discogs";
      const suffix = this.discogsActionsLength;
      return `${prefix} (${suffix})`;
    },
    closeDiscogsModal() {
      this.showDiscogsModal = false;
      this.syncInProgress = false;
    },
  },
  async created() {
    await this.init();
  },
});
