
import Vue from "vue";
// import ModalAddressEdit from "./Moda lAddressEdit.vue";
import ShipSiteAddressPanel from "./ShipSiteAddressPanel.vue";
import DiscogsAddressPanel from "./DiscogsAddressPanel.vue";
import { Warn, Info, Success } from "@/services/notif-utils";
export default Vue.extend({
  name: "AddressDetails",
  components: {
    // ModalAddressEdit,
    ShipSiteAddressPanel,
    DiscogsAddressPanel,
  },
  props: ["discogsAddress", "shipSiteAddress", "orderId"],
  data() {
    return {
      addressChanged: false,
      loading: false,
      currentTab: "shipsite",
      modalToggle: false,
      selectedAddress: null,
    };
  },
  methods: {
    edit() {
      //
    },
    closeModal() {
      this.modalToggle = false;
    },
    setAddress(data: any) {
      this.selectedAddress = data;
    },
    // resetChange(){}
  },
});
