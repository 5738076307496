
import Vue from "vue";
import copyToClipboard from 'quasar/src/utils/copy-to-clipboard.js';;
import Api from "@/api";
import { Info, Warn } from "@/services/notif-utils";
export default Vue.extend({
  name: "OrderItem",
  props: ["item", "shipsiteObject"],
  data() {
    return {
      mapLoading: false,
      map: { asin: null, auctionID: null, condition: null, sellerID: null },
      errorWithShipsiteObject: false,
    };
  },
  methods: {
    openDiscogs() {
      const itemLink = this.item.links[0];
      const url = `${itemLink}`;
      window.open(url, "_blank");
    },
    checkMapping() {
      this.mapLoading = true;
      const itemID = this.item.id;
      if (itemID == null) {
        Info("No item id found for this item");
        return;
      }
      Api.Staff.getMapForActiveListing(itemID)
        .then((response: any) => {
          this.map.asin = response.asin;
          this.map.auctionID = response.auction_id;
          this.mapLoading = false;
        })
        .catch((err) => {
          this.$q.notify({
            position: "top-right",
            type: "warning",
            message: err,
          });
        });
    },
    formatCondition(condition: string) {
      const re = /^.*\(|\)/g;
      const formattedString = condition.replace(re, "");
      return formattedString;
    },
    openLink() {
      if (this.mapCheck === "asin") {
        const asin: any = this.map.asin;
        const url = `https://www.amazon.co.jp/dp/${asin}?_encoding=UTF8&aod=1`;
        window.open(url, "_blank")?.focus();
      } else if (this.mapCheck === "yahoo") {
        const auctionId: any = this.map.auctionID;
        const url = `https://page.auctions.yahoo.co.jp/jp/auction/${auctionId}`;
        window.open(url, "_blank")?.focus();
      } else if (this.mapCheck == "mercari") {
        const auctionId: any = this.map.auctionID;
        const url = `https://jp.mercari.com/item/${auctionId}`;
        window.open(url, "_blank")?.focus();
      } else return;
    },
    getImage(image: any) {
      let imageList = [];
      if (typeof image == "string" && image != null && image != "[]") {
        const imageStr = image;
        imageStr.replace(/['"]+/g, "");
        imageList = JSON.parse(imageStr);
      } else if (typeof image == "object" && image.length !== undefined) {
        imageList = image;
      }

      for (const itemImage of imageList) {
        if (itemImage.thumb) {
          return itemImage.thumb;
        }
      }
      return "";
    },
    copyId(param: any) {
      copyToClipboard(param)
        .then(() => {
          this.$q.notify({
            type: "dark",
            position: "top",
            message: "Copied to clip board",
            icon: "content_copy",
          });
        })
        .catch(() => {
          this.$q.notify({
            position: "top",
            message: "Failed to copy",
          });
        });
    },
  },
  computed: {
    shipsiteObjectData(): any {
      if (!this.shipsiteObject) {
        return null;
      }
      const loadedItem = this.item;
      const listOfOrderItems = this.shipsiteObject as [];
      const orderItem = listOfOrderItems.filter((item: any) => {
        return loadedItem["id"] == item["listing_id"];
      });
      if (orderItem) {
        return orderItem[0];
      }
      return null;
    },
    isManager(): boolean {
      return this.$store.getters["Auth/isManager"];
    },
    isUnavailable(): boolean {
      if (!this.shipsiteObjectData) {
        return true;
      }
      return Boolean(this.shipsiteObjectData["is_available"]);
    },
    isDeleted(): boolean {
      if (!this.shipsiteObjectData) {
        return false;
      }
      return Boolean(this.shipsiteObjectData["deleted"]);
    },
    mapCheck(): string {
      const asin: any = this.map.asin;
      const auctionId: any = this.map.auctionID;
      if (asin != null) {
        return "asin";
      } else if (auctionId != null) {
        const re = /m\d+/;
        const check = auctionId.search(re);
        if (check >= 0) {
          return "mercari";
        }
        return "yahoo";
      } else {
        return "";
      }
    },
  },
  created() {
    if (this.item) {
      this.checkMapping();
    }
  },
});
