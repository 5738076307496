
import Vue from "vue";
import { mapMutations } from "vuex";
import { MutationTypes } from "@/store/general/mutation-types";
import StaffApi from "@/api/staff";
import { Success } from "@/services/notif-utils";
export default Vue.extend({
  name: "ModalAddPhone",
  props: {
    openPhoneModal: Boolean,
    orderId: String,
    threadPhoneNumber: String,
  },
  data() {
    return {
      phone: "",
      editLoading: false,
    };
  },
  methods: {
    ...mapMutations({
      postAction: "General/" + MutationTypes.ACTION_POSTED,
    }),
    onSubmit() {
      const re = /\D+/g;
      this.editLoading = true;
      const numberAdded = this.phone;
      if (numberAdded != null) {
        const cleanNumber = numberAdded.replace(re, "");
        this.$q.notify({
          position: "top-right",
          icon: "clean_hands",
          message: "Sanitization Complete",
        });
        const data = {
          phone: cleanNumber,
        };
        StaffApi.editOrderInfo(this.orderId, data)
          .then(async (response: any) => {
            this.$q.notify({
              position: "top-right",
              type: "dark",
              message: "Phone Number Request Posted!",
            });
            // const actionId = response.data.id;
            // const savedToShipSite = await StaffApi.checkIfSuccess(
            //   StaffApi.actionType.ORDER_ACTION,
            //   actionId
            // );
            // if (savedToShipSite) {
            this.postAction();
            this.$emit("closeModal");
            this.editLoading = false;
            // } else {
            // this.editLoading = false;
            // Error("Unable to Save to shipsite");
            // }
          })
          .catch(() => {
            this.$q.notify({
              position: "top-right",
              type: "negative",
              message: "Phone Number Addition Failed!",
            });
          });
      }
    },
    onReset() {
      this.phone = "";
    },
    threadNumberFound() {
      if (this.threadPhoneNumber != "") {
        this.phone = this.threadPhoneNumber;
      } else this.phone = "";
    },
  },
  computed: {
    toggleState: function(): boolean {
      return this.openPhoneModal;
    },
  },
  created() {
    this.threadNumberFound();
  },
});
