
import Vue from "vue";
export default Vue.extend({
  name: "DiscogsAddressPanel",
  props: ["discogsAddress"],
  data() {
    return {
      loading: false,
    };
  },
});
