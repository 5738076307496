
/* eslint-disable camelcase */
import Vue from "vue";
import ModalAddressEdit from "./ModalAddressEdit.vue";
export default Vue.extend({
  name: "ShipSiteAddressPanel",
  components: {
    ModalAddressEdit,
  },
  props: ["shipSiteAddress", "orderId"],
  data() {
    return {
      loading: false,
      address: null,
      modalToggle: false,
      selectedAddress: {
        name: "",
        address_line_1: "",
        address_line_2: "",
        address_line_3: "",
        city: "",
        country_name: "",
        postal_code: "",
        state_or_province: "",
      },
    };
  },
  computed: {
    loadedShipsiteAddress(): any {
      return this.shipSiteAddress;
    },
  },
  methods: {
    setSavedDetails(data: any) {
      console.log(data);
      return (this.selectedAddress = data.address);
    },
    closeModal() {
      return (this.modalToggle = false);
    },
    editAddress() {
      return (this.modalToggle = !this.modalToggle);
    },
  },
  created() {
    if (this.shipSiteAddress) {
      this.selectedAddress = this.shipSiteAddress;
    }
  },
});
