
import Vue from "vue";
import API from "@/api/shipping-api-cors/shipping";
import { Info, Warn } from "@/services/notif-utils";
import ModalInternalNotes from "./ModalInternalNotes.vue";
import ModalCustomerHistory from "./ModalCustomerHistory.vue";
export default Vue.extend({
  name: "ShipsiteLauncher",
  props: ["orderId", "username", "messageId", "accountAbbrev"],
  components: {
    ModalInternalNotes,
    ModalCustomerHistory,
  },
  data() {
    return {
      loadingHistory: false,
      loadingInternalComments: false,
      customerOrderHistory: [] as any,
      showHistoryModal: false,
      showNotesModal: false,
      syncInProgress: false,
      internalComments: [],
    };
  },

  computed: {
    managerComments(): any {
      return this.customerOrderHistory["manager_comments"];
    },
    commentExists(): boolean {
      if (this.internalComments.length > 0) {
        return true;
      }
      return false;
    },
    commentsLoaded(): boolean {
      if (this.commentExists) {
        return true;
      }
      return false;
    },
    historyCount(): number {
      const listLength = this.customerOrderHistory.length;
      return listLength;
    },
    notesCount(): number {
      if (this.internalComments) {
        const listLength = this.internalComments.length;
        return listLength;
      }
      return 0;
    },
  },
  methods: {
    async init() {
      await this.loadShipsiteDetailsForOrder();
      await this.loadInternalCommentsForOrder();
    },

    loadShipsiteDetailsForOrder() {
      this.loadingHistory = true;
      const requestData = {
        username: this.username,
      };
      API.customerOrderHistory(requestData)
        .then((res: any) => {
          console.log(res);
          this.loadingHistory = false;
          this.customerOrderHistory = res.data;
        })
        .catch((error) => {
          console.log(error);
          Warn("Problem Loading History");
          this.loadingHistory = false;
        });
    },
    loadInternalCommentsForOrder() {
      this.loadingInternalComments = true;
      // const data = {
      //   ids: JSON.stringify([this.orderId]),
      // };
      // const dataJSON = JSON.stringify(data);

      // console.log(data);
      // const postData = [this.orderId];
      API.internalNotesForOrders(this.orderId)
        .then((response: any) => {
          this.internalComments = response.data;
          this.loadingInternalComments = false;
        })
        .catch((error) => {
          console.log(error);
          this.loadingInternalComments = false;
          Warn("Unable to load Internal Comments.");
        });
    },
    toggleHistoryModal() {
      this.showHistoryModal = !this.showHistoryModal;
    },
    toggleNotesModal() {
      this.showNotesModal = !this.showNotesModal;
    },
  },
  async created() {
    await this.init();
  },
});
