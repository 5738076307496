
import Vue from "vue";
import Api from "@/api";
import { Warn, Info, Success } from "@/services/notif-utils";
import ModalSendRefund from "@/components/ModalSendRefund.vue";
export default Vue.extend({
  name: "SendRefund",
  components: {
    ModalSendRefund,
  },
  props: ["orderID", "shippingCost", "totalOrderCost", "currentAccountAbbrev"],
  data() {
    return {
      loading: false,
      openModal: false,
      hover: false,
    };
  },
  computed: {
    getOrderId(): string {
      return this.orderID;
    },
    getShippingPrice(): number {
      if (this.shippingCost) {
        const re = /\$/;
        const price = this.shippingCost.replace(re, "");
        const priceInt = parseFloat(price);
        return priceInt;
      }
      return 0;
    },
  },
  methods: {
    fetchUUID(): any {
      return new Promise((resolve, reject) => {
        Api.getUUID()
          .then((uuid) => {
            resolve(uuid);
            return uuid;
          })
          .catch((error) => {
            reject(error);
            return "";
          });
        return "";
      });
    },
    openSendRefundModal() {
      if (!this.totalOrderCost) {
        Info("No Total Price Yet");
        return;
      }
      if (!this.shippingCost) {
        Info("No Shippping Cost Loaded");
      }
      this.openModal = true;
    },
    // async sendRefund(amount: number) {
    //   this.loading = true;
    //   const uuid = await this.fetchUUID();
    //   const data = {
    //     orderId: this.getOrderId,
    //     uuid: uuid,
    //     amount: amount
    //   };
    //   Api.Staff.sendRefund(data)
    //     .then(response => {
    //       Success(`Refund Request of $${amount} Sent!`);
    //       this.loading = false;
    //     })
    //     .catch(error => {
    //       console.log(error);
    //       Warn("Refund Request Failed");
    //       this.loading = false;
    //     });
    // }
  },
});
