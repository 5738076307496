import { render, staticRenderFns } from "./OrderDetail.vue?vue&type=template&id=21d02ab8&scoped=true"
import script from "./OrderDetail.vue?vue&type=script&lang=ts"
export * from "./OrderDetail.vue?vue&type=script&lang=ts"
import style0 from "./OrderDetail.vue?vue&type=style&index=0&id=21d02ab8&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "21d02ab8",
  null
  
)

export default component.exports
import QInnerLoading from 'quasar/src/components/inner-loading/QInnerLoading.js';
import QSpinner from 'quasar/src/components/spinner/QSpinner.js';
import QChip from 'quasar/src/components/chip/QChip.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QList from 'quasar/src/components/item/QList.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QInnerLoading,QSpinner,QChip,QBtn,QItem,QItemLabel,QTooltip,QSeparator,QList,QItemSection,QCard,QCardSection,QIcon});
