
import Vue from "vue";
import ModalOrderDetails from "./ModalOrderDetails.vue";
export default Vue.extend({
  name: "OrderDetailsLauncher",
  props: ["orderId"],
  components: {
    ModalOrderDetails,
  },
  data() {
    return {
      loading: false,
      data: null,
      showDetailsModal: false,
    };
  },
  methods: {
    loadDetails() {
      this.loading = false;
      //
    },
    toggleDetails() {
      this.showDetailsModal = !this.showDetailsModal;
    },
  },
  created() {
    this.loadDetails();
  },
});
