
import Vue from "vue";
import { getAccountAbbrevForOrderId } from "@/functions/utils";
import AccountAbbrev from "@/services/AccountAbbrev";
// import { AccountAbbrev } from "@/api/staff";
import copyToClipboard from 'quasar/src/utils/copy-to-clipboard.js';;
import Api from "@/api";
import ShipsiteApi from "@/api/shipping-api-cors/shipping";
import PS from "@/services/progress-steps";
import FB from "@/services/feedback-options";
import SuggestedReplies from "@/components/SuggestedReplies.vue";
import ModalAddTaxNumber from "@/components/ModalAddTaxNumber.vue";
// import ModalAddressEdit from "@/components/ModalAddressEdit.vue";
import ModalAddPhone from "@/components/ModalAddPhone.vue";
import ButtonCancelOrder from "@/components/ButtonCancelOrder.vue";
import PageError from "@/components/PageError.vue";
import SendToAdminButton from "@/components/SendToAdminButton.vue";
import { PageState } from "@/services/page-state.interface";
import { mapActions } from "vuex";
import { mapMutations } from "vuex";
import { MessageMutations } from "@/store/messages/mutation-types";
import { ActionTypes } from "@/store/messages/action-types";
// import BuyerHistory from "@/components/BuyerHistory.vue";
import { MessageObj, OrderPageDetails } from "@/models";
import { Success, Info } from "@/services/notif-utils";
import ResponseError from "@/services/response-error";
// import ThreadObject from '@/models/'
import { DataState } from "@/services/data-states";
import { bufferMethods } from "@/services/message-buffer-helper";
import OrderItem from "@/components/OrderItem.vue";
import RemoveFeedback from "@/components/RemoveFeedbackBtn.vue";
import SendRefund from "@/components/SendRefundBtn.vue";
import TrackingDetailsModal from "@/components/TrackingDetailsModal.vue";
// import SelectShippingService from "@/components/SelectShippingService.vue";
import AddressDetails from "@/components/AddressDetails.vue";
import ActionStatusLauncher from "@/components/ActionStatusLauncher.vue";
import ShipsiteLauncher from "@/components/ShipsiteLauncher.vue";
import ModalChangeShippingService from "@/components/ModalChangeShippingService.vue";
import ModalQRCodeStats from "@/components/ModalQRCodeStats.vue";
import OrderDetailsLauncher from "@/components/OrderDetailsLauncher.vue";

interface ThreadObject {
  img: string;
  message: string;
  sender: string;
  str_time: string;
  time: string;
}
export default Vue.extend({
  components: {
    SuggestedReplies,
    // ModalAddressEdit,
    ModalAddPhone,
    // BuyerHistory,
    PageError,
    SendToAdminButton,
    ModalAddTaxNumber,
    OrderItem,
    RemoveFeedback,
    SendRefund,
    TrackingDetailsModal,
    ButtonCancelOrder,
    // SelectShippingService,
    AddressDetails,
    ActionStatusLauncher,
    ModalChangeShippingService,
    ModalQRCodeStats,
    ShipsiteLauncher,
    OrderDetailsLauncher,
  },
  name: "OrderDetail",
  data() {
    return {
      shadowAPIPageDetails: null,
      directShipsiteData: null,
      directShipsiteDataLoading: false,
      currentAccountAbbrev: AccountAbbrev.KC,
      pageError: false,
      map: {},
      showTrackingModal: false,
      // errorMsg: "",
      options: ["Google", "Facebook", "Twitter", "Apple", "Oracle"],
      pageState: PageState.LOADING,
      failMsg: "",
      countrySelected: null,
      loading: false,
      show: false,
      modalToggle: false,
      phoneModal: false,
      taxModal: false,
      expanded: false,
      FBIcon: "",
      FBType: "",
      newAddress: "",
      shippingService: "",
      managerTrackingLink: "",
      isChBound: false,
      customerCountry: "",
      orderDetails: {
        address: [],
        phone: "",
        // //@ts-expect-error
        // ship_site_id: null,
        // ship_site_state: "",
        // ship_site_shipping_box: null,
      },
      mapLoading: false,
      staffPriceField: null,
      totalOrderCost: null,
      dateShipped: "",
      trackingCode: "",
      loadedFirstBgMessage: false,
      orderItems: [],
      thread: [],
      progress: [],
      dateUpdated: "26 April-21 09:15AM",
      orderTitle: "Order #11178893-4344",
      count: 3,
      buyerRating: 5,
      customerName: "Dale Anderson",
      step: 2,
      currentStep: 0,
      text: "",
      threadModel: "",
      addressViewed: false,
      newDetail: {},
      changeShippingServiceModal: false,
      qrCodeModal: false,
      qrItems: [],
      currentRouteID: "",
    };
  },
  computed: {
    newDateShipped(): string {
      if (!this.directShipsiteData) {
        return "";
      }
      return this.directShipsiteData["date_shipped"] as string;
    },

    mapCheck(): string {
      // @ts-expect-error
      const asin: any = this.map.asin;
      // @ts-expect-error
      const auctionId: any = this.map.auctionId;
      if (asin != null) {
        return "asin";
      } else if (auctionId != null) {
        return "yahoo";
      } else {
        return "";
      }
    },
    detailsLoading(): any {
      if (this.pageError) {
        return false;
      }
      if (this.pageDetails) {
        return false;
      }
      return true;
    },
    bufferLoadingError(): boolean {
      const errorState = this.$store.getters["Buffer/getBufferErrorState"];
      return errorState;
    },
    pageDetails(): any {
      // shadowAPIPageDetails may be loaded first since those are just direct API calls to Discogs, but the others may take a while
      const properPageDetails = this.$store.getters["Buffer/getPageDetails"](
        this.currentRouteID
      );
      if (properPageDetails) {
        return properPageDetails;
      }
      return this.shadowAPIPageDetails;
    },
    // currentRouteID(): string {
    //   const routeID: string = this.$route.params.messageId;
    //   return routeID;
    // },
    nextRouteID(): string {
      const nextID = this.$store.getters["Message/getNextID"](
        this.currentRouteID
      );
      if (nextID === -1) {
        return "";
      }
      return nextID;
    },
    prevRouteID(): string {
      const prevID = this.$store.getters["Message/getPrevID"](
        this.currentRouteID
      );

      return prevID;
    },
    bufferEnd(): number {
      const bufferLength = this.$store.getters["Message/getBufferLength"];
      const end = bufferLength - 1;
      return end;
    },
    getBufferStep(): number {
      return this.$store.getters["Message/getBufferStep"];
    },
    getBufferLoadedState(): boolean {
      return this.$store.getters["Message/getBufferLoadedState"];
    },
    getBuffer(): OrderPageDetails[] {
      return this.$store.getters["Message/getBuffer"];
    },
    getNextTwo(): MessageObj[] {
      return this.$store.getters["Message/getNextTwo"](
        this.$route.params.messageId
      );
    },

    orderId(): string {
      return this.$route.params.orderId;
    },
    itemsObj(): any[] {
      let obj: any[];
      if (this.expanded) {
        return this.orderItems;
      } else {
        obj = [...this.orderItems];
        obj.splice(1);
        return obj;
      }
    },
    getName(): string {
      if (!this.orderDetails.address) {
        return "";
      }
      return this.orderDetails.address[0];
    },
    getPhone(): string {
      if (!this.orderDetails.phone) {
        return "";
      } else {
        return this.orderDetails.phone;
      }
    },
    isManager(): boolean {
      return this.$store.getters["Auth/isManager"];
    },
    getOtherAddress(): any {
      const parts: any = [];
      if (this.orderDetails.address) {
        for (const index in this.orderDetails.address) {
          if (index == "0") {
            continue;
          }
          const adPart = this.orderDetails.address[index];
          parts.push(adPart);
        }
      }
      return parts;
    },
    threadNumber(): string {
      //@ts-expect-error
      const firstMessage: string = this.thread[0].message;
      const phoneRegex =
        /(\+?( |-|\.)?\d{1,2}( |-|\.)?)?(\(?\d{3}\)?|\d{3})( |-|\.)?(\d{3,4}( |-|\.)?\d{4})/g;
      const phone = firstMessage.match(phoneRegex);
      if (phone) {
        return phone[0];
      } else return "";
    },
    taxNumber(): string {
      //@ts-expect-error
      const firstMessage: string = this.thread[0].message;
      const taxNumberRegex = /((\d{3})(\s|-|\.)*){3}(\d{2})/g;
      const taxNumber = firstMessage.match(taxNumberRegex);
      if (taxNumber) {
        return taxNumber[0];
      } else return "";
    },
    foundCFPJInThread(): boolean {
      //@ts-expect-error
      const message = this.thread[0].message;
      const re = /CFPJ\s/g;
      const CFPJ = message.match(re);
      if (CFPJ) {
        return true;
      } else return false;
    },
    percent(): any {
      if (this.buyerRating != null) {
        const calc = (this.buyerRating / 5) * 100;
        return calc;
      } else return 0;
    },
    itemsCount(): string {
      if (this.orderItems.length > 1) {
        return this.orderItems.length + " items";
      } else if (this.orderItems.length == 1) {
        return this.orderItems.length + " item";
      } else return "No Items";
    },
    isChBoundCheck(): string {
      //@ts-expect-error
      const shippingBoxDetails: any = this.orderDetails.ship_site_shipping_box;
      if (!shippingBoxDetails) {
        const check: boolean = this.isChBound;
        const isChText = "Via CH";
        const notChText = "Via Direct";
        if (check) {
          return isChText;
        } else {
          return notChText;
        }
      }
      const returnText = "Via";
      const region = shippingBoxDetails["box_type"]["name"] as "CH" | "US";
      const flags = { CH: "🇨🇭", US: "🇺🇸" };
      const flag = flags[region];
      return `${returnText} ${region} ${flag}`;
    },
    phoneMissing(): boolean {
      const phoneNumber = this.orderDetails.phone;
      const shippingService = this.shippingService;

      if (
        phoneNumber == "" &&
        (shippingService === "Yamato" || "FedEx" || "DHL")
      ) {
        return true;
      } else {
        return false;
      }
    },
    accountUsernames(): any {
      return this.$store.getters["General/accountUsernames"];
    },
    username(): any {
      if (this.thread != null) {
        const objectWithSender = this.thread.filter((thread: any) => {
          // TODO: Add support for multiple accounts
          const isAccountUsername = this.checkIfAccountUsername(thread.sender);
          return thread.sender != null && !isAccountUsername;
        });
        if (objectWithSender.length < 0) {
          console.log("sfjasdfkasdnfs");
          return "";
        }
        const username: any = objectWithSender[0]["sender"];
        if (username) {
          return username;
        }
      }
      return "";
    },
    canCancelOrder(): boolean {
      if (this.pageDetails) {
        const currentStatus = this.progress[this.currentStep];
        console.log("current status " + currentStatus);
        if (currentStatus == "Shipped" || currentStatus == "Cancelled") {
          return false;
        }
        return true;
      }
      return false;
    },
    canChangeShippingService(): boolean {
      if (this.pageDetails) {
        const currentStatus = this.progress[this.currentStep];
        if (currentStatus == "Invoice Sent") {
          return true;
        }
        return false;
      }
      return false;
    },
    customerDetails(): any {
      if (this.pageDetails) {
        const customerObj = this.pageDetails["ship_site_customer"];
        if (customerObj) {
          return customerObj;
        }
        return {};
      }
      return {};
    },
  },
  watch: {
    pageDetails() {
      this.checkAddressViews();
      this.renderPage();
    },
    bufferLoadingError() {
      if (this.bufferLoadingError == true) {
        this.failMsg = this.$store.getters["Buffer/getBufferErrorMessage"];
        this.loading = false;
      } else {
        this.failMsg = "";
      }
    },
  },
  methods: {
    ...mapActions({
      markAsReadAction: "Message/" + ActionTypes.MARK_AS_READ,
    }),
    ...mapMutations({
      setBufferState: "Message/" + MessageMutations.SET_BUFFER_STATE,
      setBuffer: "Message/" + MessageMutations.SET_BUFFER,
      setBufferLoaded: "Message/" + MessageMutations.SET_BUFFER_LOADED,
      setBufferStep: "Message/" + MessageMutations.SET_BUFFER_STEP,
      addBufferStep: "Message/" + MessageMutations.ADD_BUFFER_STEP,
      setBufferLoading: "Message/" + MessageMutations.SET_BUFFER_LOADING,
    }),
    // vatStatusClass() {},
    // getCurrentAccountAbbrev(){

    // },
    checkIfAccountUsername(username: string) {
      return this.accountUsernames.includes(username);
    },
    checkTaxStatus(priceFields: any[]) {
      const re = /VAT/;
      const taxRe = /Tax/;
      const vatObject = priceFields.find((field: any) => {
        return field.title.search(re) >= 0;
      });
      if (vatObject) {
        return `${vatObject.title} ${vatObject.price}`;
      }
      const taxObject = priceFields.find((field: any) => {
        return field.title.search(taxRe) >= 0;
      });
      if (taxObject) {
        return `${taxObject.title}: ${taxObject.price}`;
      }
      return "Tax not Paid";
    },
    getTotalPrice(priceFields: any) {
      const re = /Total/;
      const foundObject = priceFields.find((field: any) => {
        return field.title.search(re) >= 0;
      });
      console.log(foundObject);
      if (foundObject) {
        return foundObject;
      }
      return {};
    },
    openLink() {
      //
    },
    closeQrCodeModal() {
      return (this.qrCodeModal = false);
    },
    getQRCodeStats(): string {
      if (!this.directShipsiteData) {
        return "No QR Data";
      }
      const shipSiteOrderItemsArray = this.directShipsiteData[
        "order_items"
      ] as any[];
      this.qrItems = shipSiteOrderItemsArray as any;
      // this.qrCodeModal = true;
      if (shipSiteOrderItemsArray == null) {
        return "";
      }
      const itemsCount = shipSiteOrderItemsArray.length;
      const isProcessedArray = shipSiteOrderItemsArray.filter((el: any) => {
        return el["is_processed"] == true;
      });
      const processedCount = isProcessedArray.length;
      const statsString = `${processedCount} of ${itemsCount} are Ready...`;
      return statsString;
    },
    sortQrCodeProccessedOrders() {
      const items: any[] = [];
      if (!this.directShipsiteData) {
        return [];
      }
      const shipSiteOrderItemsArray = this.directShipsiteData[
        "order_items"
      ] as [];
      if (shipSiteOrderItemsArray == null) {
        return [];
      }
      for (const orderitem of shipSiteOrderItemsArray) {
        items.push(orderitem);
      }
      items.sort((a, b) => {
        if (a.is_processed && b.is_processed) {
          const d1 = new Date(a.date_is_processed_full);
          const d2 = new Date(b.date_is_processed_full);
          if (d1 < d2) {
            return -1;
          }
          if (d1 === d2) {
            return 0;
          }
          return 1;
        }
        return 0;
      });
      return items;
    },
    getQrLastProccessedDate() {
      const sortedList = this.sortQrCodeProccessedOrders() as [];
      console.log(sortedList);
      if (!sortedList) {
        // console.log(sortedList);
        console.log("NO Order Items in sorted list");
        return "";
      }
      const latest = sortedList.pop();
      if (latest) {
        if (latest["date_is_processed"] == undefined) {
          return "";
        }
        return latest["date_is_processed"];
      }
      return;
    },
    onPageChange() {
      bufferMethods.loadIds(this.currentRouteID);
    },
    fetchUsername() {
      if (this.thread != null) {
        // for (const singleThread of this.thread) {
        const objectWithSender = this.thread.filter((thread: any) => {
          return thread.sender != null;
        });
        // console.log(objectWithSender);
        if (objectWithSender.length < 0) {
          console.log("HUdisafhadsiufhadf");
          console.log(objectWithSender);
          return "";
        }
        const username: any = objectWithSender[0]["sender"];
        if (username) {
          return username;
        }
        // }
      }
      return "";
    },
    copyId(param: any) {
      copyToClipboard(param)
        .then(() => {
          this.$q.notify({
            type: "dark",
            position: "top",
            message: "Copied to clip board",
            icon: "content_copy",
          });
        })
        .catch(() => {
          this.$q.notify({
            position: "top",
            message: "Failed to copy",
          });
        });
    },
    setProgressChip(currStep: string) {
      console.log("progress");
    },
    formatCondition(condition: string) {
      const re = /^.*\(|\)/g;
      const formattedString = condition.replace(re, "");
      return formattedString;
    },
    trackingLink(code: any) {
      this.copyId(code);
      let link = `https://trackings.post.japanpost.jp/services/srv/search/direct?reqCodeNo1=${code}&locale=en`;
      if (this.shippingService == "Yamato") {
        link = `http://track.kuronekoyamato.co.jp/english/tracking`;
      }
      if (this.shippingService == "DHL") {
        link = `https://www.mydhli.com/global-en/home/tracking/tracking-express.html?submit=1&tracking-id=${this.trackingCode}`;
      }
      if (this.shippingService == "UPS Saver") {
        link = `https://www.ups.com/track?loc=en_US&requester=QUIC&tracknum=${this.trackingCode}/trackdetails`;
      }
      if (this.shippingService == "UPS WorldWideEconomy") {
        link = `https://www.ups.com/track?loc=en_US&requester=QUIC&tracknum=${this.trackingCode}/trackdetails`;
      }
      // DEPRECATED CODE
      // if (this.managerTrackingLink) {
      //   link = this.managerTrackingLink;
      //   for (const field of ["{code}", "{tracking_code}", "{trackingCode}"]) {
      //     link = link.replace(field, code);
      //   }
      // }
      window.open(link, "_blank")?.focus();
    },
    orderStatus(): string {
      const discogsStatus = this.progress[this.currentStep];
      const shipSiteStatus = this.pageDetails["status_verbose"];
      if (discogsStatus == PS.SHIPPED) {
        if (this.dateShipped != null) {
          const prefix = this.dateShipped;
          return `Shipped On(${prefix})`;
        }
        const statusString = `Discogs Status:${discogsStatus} //// Actual Status:${shipSiteStatus}`;
        return statusString;
      } else {
        return "Preparing";
      }
    },
    priceField() {
      return;
    },
    closeModal() {
      this.modalToggle = false;
    },
    closeTaxModal() {
      this.taxModal = false;
    },
    addPhoneNumber() {
      //@ts-expect-error
      const firstMessage: string = this.thread[0].message;
      const phoneRegex =
        /(\+?( |-|\.)?\d{1,2}( |-|\.)?)?(\(?\d{3}\)?|\d{3})( |-|\.)?(\d{3,4}( |-|\.)?\d{4})/g;
      const phone = firstMessage.match(phoneRegex);
      if (phone) {
        this.threadModel = phone[0];
        this.$q.notify({
          position: "top",
          message: "Tel Copied from Thread!",
        });
      }
      this.phoneModal = true;
    },
    addTaxNumber() {
      if (this.taxNumber) {
        this.$q.notify({
          position: "top",
          message: "Tax Number copied from latest message!",
        });
      }
      this.taxModal = true;
    },
    closePhoneModal() {
      this.phoneModal = false;
    },
    expandItems() {
      this.expanded = !this.expanded;
    },
    getImage(image: any) {
      let imageList: any[] = [];
      console.log(image);
      console.log(typeof image);
      if (typeof image == "string" && image != null && image != "[]") {
        const imageStr = image;
        imageStr.replace(/['"]+/g, "");
        imageList = JSON.parse(imageStr);
      } else if (typeof image == "object" && image.length !== undefined) {
        imageList = image;
      }

      for (const itemImage of imageList) {
        if (itemImage.thumb) {
          return itemImage.thumb;
        }
      }
      return "";
    },
    getIcon(step: string): string {
      switch (step) {
        case PS.NEW_ORDER:
          return "add_shopping_cart";
        case PS.PAYMENT_PENDING:
          return "sync";
        case PS.PAYMENT_RECEIVED:
          return "paid";
        case PS.SHIPPED:
          return "local_shipping";
      }
      return "";
    },
    setFBI(fb: string): string {
      switch (fb) {
        case FB.POSITIVE:
          return "thumb_up";
        case FB.NEUTRAL:
          return "sentiment_neutral";
        case FB.NEGATIVE:
          return "thumb_down";
      }
      return "";
    },
    setFBT(fb: string) {
      switch (fb) {
        case FB.POSITIVE:
          return "positive";
        case FB.NEUTRAL:
          return "grey-4";
        case FB.NEGATIVE:
          return "red-6";
      }
      return "";
    },
    timelineIcon(imgUrl: any) {
      if (imgUrl) {
        return imgUrl;
      }
    },
    editAddress() {
      this.modalToggle = !this.modalToggle;
    },
    changeShow() {
      this.show = true;
      Api.Staff.showedAddress(this.orderId);
    },
    hide() {
      this.show = false;
    },
    openPage(
      accountAbbrev: AccountAbbrev,
      type: any,
      id: string,
      typeId?: string
    ) {
      return Api.Staff.getPage(this.currentAccountAbbrev, type, id, typeId);
    },
    renderPage() {
      this.loading = true;
      const obj = this.pageDetails;
      if (obj != null) {
        this.thread = obj.message_thread;
        this.progress = obj.progress;
        this.orderDetails = obj;
        if (obj.customer_country) {
          this.customerCountry = obj.customer_country;
        }
        this.currentAccountAbbrev = obj.account_abbrev;
        this.shippingService = obj.shipping_service;
        this.orderItems = obj.items;
        this.buyerRating = obj.buyer_rating;
        this.isChBound = obj.is_ch_bound;
        this.staffPriceField = obj.price_fields[1];
        this.totalOrderCost = this.getTotalPrice(obj.price_fields);
        this.dateShipped = obj.date_shipped;
        this.trackingCode = obj.tracking_code;
        // DEPRECATED CODE
        // this.managerTrackingLink = "";
        // if (obj.shipping_method && obj.shipping_method.manager_tracking_link) {
        //   this.managerTrackingLink = obj.shipping_method.manager_tracking_link;
        // }
        Success("Successfully Loaded");
        const calc = obj.progress.length - 1;
        if (calc > 0) {
          this.currentStep = calc;
          this.loading = false;
          this.pageState = PageState.SUCCESS;
        }
        this.pageState = PageState.SUCCESS;
        this.loading = false;
      }
    },
    checkBuffer() {
      const mID = this.$route.params.messageId;
      const indexInBuffer = this.getBuffer.findIndex(
        ({ messageId }) => messageId == mID
      );
      return indexInBuffer;
    },
    checkAddressViews() {
      const orderId = this.$route.params.orderId;
      if (orderId) {
        Api.Staff.checkShowedAddress(orderId)
          .then((response) => {
            this.addressViewed = response.data.shown;
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    testHistory() {
      const data = {
        username: "Christopher7xii",
      };
      return ShipsiteApi.customerOrderHistory(data);
    },
    loadOrderFromDiscogsAPI() {
      const guessedAccountAbbrev = getAccountAbbrevForOrderId(
        this.orderId
      ) as AccountAbbrev;
      Api.Staff.apiOrderInfo(guessedAccountAbbrev, this.orderId).then(
        (data: any) => {
          this.shadowAPIPageDetails = data;
        }
      );
    },
    async loadOrderFromShipsiteAPI() {
      this.directShipsiteDataLoading = true;
      const currentOrderID = this.orderId;
      ShipsiteApi.apiOrderData(currentOrderID)
        .then((responseData: any) => {
          this.directShipsiteData = responseData.data;
          this.directShipsiteDataLoading = false;
        })
        .catch((err) => {
          console.log(err);
          this.directShipsiteDataLoading = false;
        });
    },
    setCurrentRouteID() {
      const messageIDFromParams = this.$route.params.messageId;
      if (messageIDFromParams) {
        this.currentRouteID = messageIDFromParams;
        return messageIDFromParams;
      }
      const messageIdFromList = this.$store.getters[
        "Message/messageIdByOrderID"
      ](this.orderId);
      this.currentRouteID = messageIdFromList;
      return messageIdFromList;
    },
  },
  async created() {
    this.setCurrentRouteID();
    this.markAsReadAction(this.currentRouteID);
    if (!this.pageDetails) {
      await this.loadOrderFromShipsiteAPI();
      this.loadOrderFromDiscogsAPI();
    }
    bufferMethods.loadIds(this.currentRouteID).catch((err) => {
      console.log("error was caught");
      console.log(err);
      // if()
      this.pageState = PageState.FAIL;
    });
    this.renderPage();
    this.testHistory();
  },
});
