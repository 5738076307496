
import Vue from "vue";
import api from "@/api/staff";
import DateUtils from "@/services/date-utils";
export default Vue.extend({
  name: "DiscogsActionModal",
  props: ["showModal", "discogsActions", "syncLoading"],
  data() {
    return {
      loading: false,
      active: true,
      intervalID: null || Number(),
      fullyLoaded: false,
      syncInProgress: false,
    };
  },
  watch: {
    allActionsLoaded() {
      if (this.allActionsLoaded) {
        if (this.intervalID) {
          clearInterval(this.intervalID);
          // @ts-expect-error
          this.intervalID = null;
          this.syncInProgress = false;
        }
      }
      this.autoSync();
    },
  },
  computed: {
    allActionsLoaded(): boolean {
      const actions = this.discogsActions;
      if (actions) {
        const actionsLength = actions.length;
        const processedActions = actions.filter((action: any) => {
          return action.processed == 1;
        });
        const processedCount = processedActions.length;
        if (processedCount == actionsLength) {
          return true;
        }
        return false;
      }
      return false;
    },
    // getActions(){}
    // syncInProgress(): boolean {
    //   return this.syncLoading;
    // },
  },
  methods: {
    close() {
      if (this.intervalID) {
        clearInterval(this.intervalID);
      }
      // @ts-expect-error
      this.intervalID = null;
      return this.$emit("closeModal");
    },
    isLoading(action: any) {
      if (action) {
        console.log(action.processed);
        const processed = Boolean(action.processed);
        return !processed;
      }
      return false;
    },
    getIcon(action: any) {
      console.log("Getting ICon");
      if (action) {
        const processed = Boolean(action.processed);
        const success = Boolean(action.success);
        if (processed && success) {
          return "check_circle";
        }
        if (processed && !success) {
          return "cancel";
        }
        return "do_not_disturb_on";
      }
    },
    actionStateClass(action: any) {
      console.log("getting state class");
      if (action) {
        const processed = Boolean(action.processed);
        const success = Boolean(action.success);
        if (processed && success) {
          return "positive";
        }
        if (processed && !success) {
          return "negative";
        }
        return "grey-8";
      }
    },
    timeAgoFormat(date: string) {
      const dateF = DateUtils.getTimeAgo(date);
      return dateF;
    },
    sync(auto = true) {
      return this.$emit("sync", auto);
    },
    autoSync() {
      if (this.allActionsLoaded == false) {
        this.syncInProgress = true;
        const iid = setInterval(this.sync, 3000);
        this.intervalID = iid;
      }
    },
  },
  mounted() {
    this.autoSync();
  },
});
