
import Vue from "vue";
import StaffApi from "@/api/staff";
import Api from "@/api/index";
import { Info } from "@/services/notif-utils";

export default Vue.extend({
  name: "ShippingServiceSelect",
  props: ["orderId", "currentAccountAbbrev"],
  data() {
    return {
      loading: false,
      selectedService: "",
      shippingServices: [],
      intervalId: null || Number(),
      outboundQueueID: "",
    };
  },
  methods: {
    clear() {
      clearInterval(this.intervalId);
      // @ts-expect-error
      this.intervalId = null;
    },
    fetchUUID(): any {
      return new Promise((resolve, reject) => {
        Api.getUUID()
          .then((uuid) => {
            resolve(uuid);
            return uuid;
          })
          .catch((error) => {
            reject(error);
            return "";
          });
        return "";
      });
    },
    isSuccess(responseData: any) {
      const processed = Boolean(responseData["processed"]);
      const success = Boolean(responseData["success"]);
      if (processed && !success) {
        return false;
      }
      if (processed && success) {
        return true;
      }
    },
    async selectService(service: string) {
      this.selectedService = service;
      this.$emit("serviceSelected", service);
    },
    async getShippingMethodRequest() {
      const uuid = await this.fetchUUID();
      const data = {
        orderId: this.orderId,
        uuid: uuid,
      };
      StaffApi.getShippingMethods(data, this.currentAccountAbbrev)
        .then((response: any) => {
          const responseData = response.data;
          this.outboundQueueID = responseData["id"];
          return responseData["id"];
        })
        .catch((error) => {
          return null;
        });
    },
    async getMethodsFromApi() {
      this.loading = true;
      const oqID = this.outboundQueueID;
      if (oqID == "") {
        return Info("Unable to get shipping method request.");
      }
      StaffApi.getSingleOutboundQueueItem(oqID)
        .then((responseData: any) => {
          const isLoaded = this.isSuccess(responseData);
          if (!isLoaded) {
            return;
          }
          this.shippingServices = responseData.response["data"];
          this.clear();
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.clear();
          this.loading = false;
          return null;
        });
    },
    async loadMethods() {
      const intervalID = setInterval(this.getMethodsFromApi, 1000);
      this.intervalId = intervalID;
    },
  },
  async created() {
    await this.getShippingMethodRequest();
    await this.loadMethods();
  },
  beforeDestroy() {
    this.clear();
  },
});
