
/* eslint-disable camelcase */
import Vue from "vue";
import { mapMutations } from "vuex";
import { MutationTypes } from "@/store/general/mutation-types";
import StaffApi from "@/api/staff";
import api from "@/api/index";
import { Success, Info, Warn } from "@/services/notif-utils";
export default Vue.extend({
  name: "ModalAddtaxString",
  props: {
    openModal: Boolean,
    taxString: String,
    orderId: {
      type: String,
      required: true,
    },
    foundCFPJ: Boolean,
  },
  data() {
    return {
      type: null,
      taxStringInput: null,
      editLoading: false,
      taxType: "CPF",
    };
  },
  computed: {
    toggleState(): boolean {
      return this.openModal;
    },
  },
  methods: {
    ...mapMutations({
      postAction: "General/" + MutationTypes.ACTION_POSTED,
    }),
    onSubmit() {
      this.editLoading = true;
      if (!this.taxStringInput) {
        return this.$q.notify({
          position: "top",
          type: "warning",
          message: "No Number in Input",
        });
      }
      const data = {
        tax_id_type: this.taxType,
        tax_id_value: this.taxStringInput,
      };
      StaffApi.editOrderInfo(this.orderId, data)
        .then(async (response: any) => {
          console.log(response);
          Info(
            `Tax number:${this.taxStringInput},is being processeed by shipsite, please wait :), check ActionStatus Tab for success Update`
          );
          this.postAction();
          this.editLoading = false;
          this.$emit("closeModal");
          return;
        })
        .catch((err) => {
          console.log(err);
          this.$q.notify({
            position: "top",
            type: "warning",
            message: "Number not saved, error",
          });
          this.editLoading = false;
        });
      //Comment
    },
    onReset() {
      this.taxStringInput = null;
    },
    checkForCFPJ() {
      if (this.foundCFPJ) {
        return (this.taxType = "CFPJ");
      }
      return (this.taxType = "CPF");
    },
  },
  created() {
    this.checkForCFPJ();
  },
});
