
import Vue from "vue";
import { mapMutations } from "vuex";
import { MutationTypes } from "@/store/general/mutation-types";
import StaffApi from "@/api/staff";
import { countryFunctions } from "@/services/country-helper";
import { Info, Success, Warn } from "@/services/notif-utils";

export default Vue.extend({
  name: "ModalAddressEdit",
  props: ["openModal", "orderId", "shipSiteAddress"],
  data() {
    return {
      currentAddressFormSaved: false,
      address: {
        name: "",
        address_line_1: "",
        address_line_2: "",
        address_line_3: "",
        state_or_province: "",
        city: "",
        country: {
          code: "",
          name: "",
        },
        postal_code: "",
      },
      countrySelected: "",
      countryCode: "",
      editLoading: false,
      options: countryFunctions.getCountryNames(),
      modalToggle: false,
    };
  },
  watch: {
    countrySelected() {
      if (this.countrySelected != "") {
        this.countryCode = countryFunctions.getCountryCode(
          this.countrySelected
        );
        this.setCountry();
      } else {
        this.countryCode = "";
        this.address["country"] = {
          code: "",
          name: "",
        };
      }
    },
  },
  methods: {
    ...mapMutations({
      postAction: "General/" + MutationTypes.ACTION_POSTED,
    }),
    setCountry() {
      this.address["country"]["name"] = this.countrySelected;
      this.address["country"]["code"] = this.countryCode;
      return;
    },
    filterFn(val: any, update: any, abort: any) {
      update(() => {
        const needle = val.toLowerCase();
        const names: string[] = countryFunctions.getCountryNames();
        this.options = names.filter(
          (v) => v.toLowerCase().indexOf(needle) > -1
        );
      });
    },
    closeModal() {
      return this.$emit("closeModal");
    },
    async onSubmit() {
      this.editLoading = true;
      const data = {} as any;
      for (const field of ["address"]) {
        // @ts-expect-error
        const val = this[field];
        if (val !== null && val != undefined) {
          data[field] = val;
        }
      }
      const countryInfo = this.countrySelected;
      console.log(countryInfo);
      if (countryInfo == "") {
        Info("Country cannot be blank, please set country.");
        this.editLoading = false;
        return;
      }
      StaffApi.editOrderInfo(this.orderId, data)
        .then(async (r) => {
          console.log(r);
          this.$q.notify({
            position: "top-right",
            type: "dark",
            message: "Address Change Request Created",
          });
          this.currentAddressFormSaved = true;
          this.editLoading = false;
          this.postAction();
          this.$emit("setSavedDetails", data);
          this.closeModal();
        })
        .catch((e) => {
          this.$q.notify({
            position: "top-right",
            type: "negative",
            message: "Address Change Request Failed",
          });
          this.editLoading = false;
        });
    },
    onReset() {
      this.countrySelected = "";
      this.address = {
        name: "",
        address_line_1: "",
        address_line_2: "",
        address_line_3: "",
        country: {
          code: "",
          name: "",
        },
        state_or_province: "",
        city: "",
        postal_code: "",
      };
      this.currentAddressFormSaved = false;
    },
    setDetails() {
      if (this.shipSiteAddress) {
        const currAddress = this.shipSiteAddress;
        this.address["name"] = currAddress["name"];
        this.address["address_line_1"] = currAddress["address_line_1"];
        this.address["address_line_2"] = currAddress["address_line_2"];
        this.address["address_line_3"] = currAddress["address_line_3"];
        this.address["city"] = currAddress["city"];
        this.address["state_or_province"] = currAddress["state_or_province"];
        this.address["postal_code"] = currAddress["postal_code"];
        if (Object.entries(currAddress["country"]).length > 0) {
          this.countrySelected = currAddress["country"]["name"];
          this.address["country"]["name"] = this.countrySelected;
        }
        return;
      }
      return;
    },
  },
  computed: {
    toggleState: function (): boolean {
      return this.openModal;
    },
  },
  created() {
    this.setDetails();
  },
});
