
import Vue from "vue";
import { mapMutations } from "vuex";
import { MutationTypes } from "@/store/general/mutation-types";
import StaffApi from "@/api/staff";
import Api from "@/api/index";
import { Warn, Success, Info } from "@/services/notif-utils";
import ModalConfirm from "@/components/ModalConfirm.vue";
import TouchSwipe from 'quasar/src/directives/TouchSwipe.js';;

export default Vue.extend({
  name: "CancelOrderBtn",
  components: {
    ModalConfirm,
  },
  props: ["orderId", "currentAccountAbbrev"],
  data() {
    return {
      loading: false,
      cancelOrderOption: "The buyer wishes to cancel this transaction.",
      openModal: false,
    };
  },
  methods: {
    ...mapMutations({
      postAction: "General/" + MutationTypes.ACTION_POSTED,
    }),
    fetchUUID(): any {
      return new Promise((resolve, reject) => {
        Api.getUUID()
          .then((uuid) => {
            resolve(uuid);
            return uuid;
          })
          .catch((error) => {
            reject(error);
            return "";
          });
        return "";
      });
    },
    async cancelOrderInShipsite() {
      //
    },
    async cancelOrder() {
      this.loading = true;
      const uuid = await this.fetchUUID();
      const data = {
        uuid: uuid,
        orderId: this.orderId,
        cancelOrderOption: this.cancelOrderOption,
      };
      const shipsiteData = {
        status: {
          id: 10,
          name: "Cancelled",
          short_form: "X",
        },
      };
      StaffApi.cancelOrder(data, this.currentAccountAbbrev)
        .then(async (response: any) => {
          Info("Cancel Order Request Initiated!");
          Info("Canceling Order in Browser, please wait...");
          this.postAction();

          await StaffApi.editOrderInfo(this.orderId, shipsiteData)
            .then((response) => {
              Info("Cancelling Order In Shipsite, please wait...");
              this.postAction();
              this.loading = false;
              return;
            })
            .catch((error) => {
              console.log("Unable to Cancel in Shipsite");
              this.loading = false;
              return;
            });
          return;
        })
        .catch((error) => {
          this.loading = false;
          Warn("Unable to Cancel Order, Server Issue");
        });
    },
    closeModal() {
      return (this.openModal = false);
    },
  },
});
